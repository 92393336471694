// ==============================================================================
// Base Typography
//
// ==============================================================================
                                   
html {
    font-family: $base-font;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
 
sup {
  top: -0.5em;
}
 
sub {
  bottom: -0.25em;
}

b, strong {
    font-weight: bold;
}

i, em {
    font-style: italic;
}
    
// ==============================================================================
// Page Content
//
// Standard content block for majority of pages
// ==============================================================================

.page-content {
    // Container for general page content
    // With base definitions for font of all contained elements
    padding: 30px 15px;
    font-size: 1.25em;
    line-height: 1.4;

    &--no-padding {
        padding: 0;
    }
    
    h1, h2, h3, h4, h5, h6 {
        // Page Content Header shared styles
        @include content-width;
        color: $mla-purple;
        font-weight: bold;
        margin-bottom: 15px;
    }

    h1 {
        // Main Heading
        font-size: 2em;
    }

    h2 {
        // Sub Heading
        font-size: 1.5em;
    }
    
    p {
        // Standard Paragraph
        @include content-width;
        margin-bottom: 15px;
    }
        
    ol, ul {
        // Ordered / Unordered Lists
        @include content-width;        
        list-style-position: inside;
        margin-bottom: 15px;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        margin-left: 15px;
    }

    table {
        // In-content table
        margin: 30px 0;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }

    th {
        background: $mla-purple;
        color: $white;
        text-align: left;
    }

    th, td {
        border-bottom: 1px solid $grey-78;
        padding: 0.5em;
    }

}

// Selector to target any in-content links (not buttons)
// Unable to nest above due to sass compiler error regarding :not()
                                                                 
.page-content a:not(.button) {
    // In-content links
    color: $mla-purple;

    &:hover {
        color: $black;
    }
}
