$slider-transition-duration: 0.3s;

.slider {
    user-select: none;
    position: relative;
    overflow: hidden;
    touch-action: manipulation;
    -webkit-user-select: none;

    &.is-dragging {
        // .slider.is-dragging
        cursor: grabbing;
    }

    &.is-loading {
        // .slider.is-loading
        position: relative;

        > * + * {
            // .slider.is-loading > * + *
            // Hide all slides except the first one.
            display: none;
        }

        &:after {
            // .slider.is-loading:after
            // Loading spinner.
            content: '';
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(white, 0.5);
            border: 4px solid $mla-purple;
            border-top-color: transparent;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            margin: auto;

        }

        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
    }


}

.slider-track {
    display: flex;
    width: 100%;
    transition: transform $slider-transition-duration ease;
}

.slider-slide {
    flex: 0 0 100%;
}

//------------------------------------------------------------------------------
// NAVIGATION (NEXT/PREVIOUS BUTTONS).
//------------------------------------------------------------------------------
.slider-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;
}

.slider-nav-button {
    pointer-events: all;
    width: 30px;
    height: 80px;
    background-color: rgba(white, 0.2);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-indent: -9999px;
    overflow: hidden;
    transition: background-color 0.3s ease;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto 0;

    &:hover,
    &:active {
        // .slider-nav-button:hover, 
        // .slider-nav-button:active
        background-color: rgba(white, 0.5);
    }

    &[disabled] {
        // .slider-nav-button[disabled]
        opacity: 0.5;
        cursor: not-allowed;
    }
}


.slider-nav-button-prev {
    left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%23981D97" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 6L8 12L14 18"/></svg>');
}

.slider-nav-button-next {
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%23981D97" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 6L16 12L10 18"/></svg>');
}

//------------------------------------------------------------------------------
// PAGINATION (DOT NAVIGATION)
//------------------------------------------------------------------------------

.slider-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.slider-pagination-dot {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    width: 20px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:before {
        // .slider-pagination-dot:before
        content: '';
        display: block;
        width: 9px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: $grey-92;
        transition:
            transform $slider-transition-duration ease,
            background-color 0.3s ease;
        transform: scale(0.9);
    }

    &:hover:before {
        // .slider-pagination-dot:hover:before
        transform: scale(1.1);
        background: $grey-87;
    }

    &.active:before {
        // .slider-pagination-dot.active:before
        transform: scale(1.2);
        background: $mla-purple;
    }
}