//******************************************************************************
// Form Action Link.
//
// Form styled to resemble a simple text hyperlink.
//******************************************************************************

.form-action-link {
    // Applied to a <form> element.
    //
    // Note: <form> elements are not allowed inside a <p>. Instead, use <div> as
    // a substitute for parent paragraph elements.

    display: inline-block;

    &__input {
        // .form-action-link__input
        // Label styled to look and behave like a standard <a> hyperlink.
        appearance: none;
        -webkit-appearance: none;
        text-decoration: underline;
        cursor: pointer;
        background: none;
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        text-align: left;
        height: auto;
        background: none;
        color: inherit;
        outline: inherit;

        &:hover {
            // .form-action-link__input:hover
            color: inherit;
        }
    }
}