// ******************************************************************************
// Globals.
//
// General rules that should apply and can be used site-wide.
// ******************************************************************************

// apply a natural box layout model to all elements, but allowing components to change
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.divider {
    // General-use full-width content container with bottom border dividing line.
    border-bottom: 1px solid $grey-78;
    padding-left: 15px;
    padding-right: 15px;

    &--no-padding {
        // .divider--no-padding
        // Divider container that allows content to span edge-to-edge with no padding.
        padding-left: 0;
        padding-right: 0;
    }

    &--no-border {
        border-bottom: 0;
    }
}

.container {
    // General-use constrained-width and centered content container.
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    display: block;

    &--grid {
        // .container--grid
        // Set up defaults for grid positioning
        display: grid;
        grid-column-gap: 0;

        @media #{$desktop} {
            grid-column-gap: 16px;
        }
    }
}

nav {
    // Flattens all nav lists
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.panel__heading {
    // Standard panel headings
    font-size: 1.2em;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 1em;
}

.heading--ruled {
    // Modifier that gives headings a light-grey horizontal rule
    padding-bottom: 15px;
    border-bottom: 1px solid $grey-92;
}

img {
    // Fluid images
    max-width: 100%;
    height: auto;
}

.active {
    // js enabled class to show hidden elements
    display: block;
}

button {
    // Default for <button> element
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    text-align: inherit;
    color: inherit;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
}

[hidden] {
    // Ensure hidden attribute is not overriden
    display: none !important;
}

input,
textarea {
    // Default text input area appearance
    font-family: inherit;
    font-size: 1em;
    line-height: 1.2;
    padding: 7px;
    border: 1px solid $grey-92;
    margin: 0;
}

input {
    height: 45px;
}

select {
    // Default combo-select appearance
    height: 45px;
}

.telephone-link {
    // Telephone number link
    color: inherit;
    text-decoration: inherit;

    &:hover {
        text-decoration: inherit;        
    }
}

time {
    // Date/time label
    white-space: nowrap;
}

.bg--grey {
    // Set background to grey
    // Form elements will look to find this class on the parent container
    // to inform their styles.
    background-color: $grey-96;
}

//==============================================================================
// Lazy Loading Images.
//
// Provide fade in transition for images loaded using yall.js library.
//==============================================================================

.lazy {
    // Images that will be lazy loaded, hidden on page load.
    transition: opacity 0.4s ease-in;
    opacity: 0;

    &.is-loaded {
        // .lazy.is-loaded
        // State that is added when the image has been loaded.
        opacity: 1;
    }
}
