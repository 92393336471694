//******************************************************************************
// Site Footer.
// 
//******************************************************************************

.footer {
    // Website footer container (<footer> element).
    background-color: $grey-92;
    color: $grey-53;
    padding: 70px 30px;
    line-height: 1.6;
    
    @media (min-width: 850px) {
        padding: 80px 30px 120px 30px;
    }
    
    @media #{$xxl} {
        font-size: 1.1em;
    }
}

//==============================================================================
// Footer Primary.
// 
// Container row for navigation menu & Knightsbridge logo.
//==============================================================================

.footer-primary {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 850px) {
        flex-direction: row;
    }
}

//==============================================================================
// Footer Navigation Menu.
//==============================================================================

.footer-menu {
    // Footer navigation menu
    
    @media (min-width: 600px) {
        display: inline-flex;
        flex-wrap: wrap;
        margin: auto;
    }

    @media (min-width: 850px) {
        margin: 0;
    }

    &__group {
        // Footer menu list object
        padding-bottom: 30px;
        padding-right: 30px;
        
        @media #{$xl} {
            padding-right: 100px;
        }
        
    }

    &__title {
        color: $grey-20;
        font-weight: bold;
    }

    &__link {
        // Footer menu link text - to match mega menu on mobile
        display: block;
        color: inherit;
        text-decoration: none;
        text-decoration: underline;
        text-decoration-color: rgba($grey-53, 0.01);
        transition-property: text-decoration-color;
        transition-duration: 0.3s;
        
        &:hover {
            // .footer-contact__link:hover
            text-decoration-color: $grey-20;
        }
    }
}

//==============================================================================
// Knightsbridge Logo.
//==============================================================================

.footer-logo {
    // SVG Knightsbridge Logo.
    display: block;
    background-image: url('/images/knightsbridge-logo.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 65px;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    
    @media (min-width: 850px) {
        margin-right: 0;
    }
    
    @media #{$xxl} {
        max-width: 300px;
    }
    
    @media #{$xxxl} {
        max-width: 400px;
    }
}

//==============================================================================
// Footer Secondary.
// 
// Container row for contact links & copyright message.
//==============================================================================

.footer-secondary {
    text-align: center;
    
    @media (min-width: 850px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
    }
}

//==============================================================================
// Contact Details.
//==============================================================================

.footer-contact {
    // Container for social media links & email/phone contact details.
    margin-bottom: 20px;
    
    @media (min-width: 850px) {
        text-align: right;
        margin-bottom: 0px;
    }
    
    &__links {
        // Container for email & phone contact details.
        // .footer-contact__links
        margin: 0 -10px;
    }
    
    &__link {
        // .footer-contact__link
        color: inherit;
        text-decoration: none;
        padding: 10px;
        text-decoration: underline;
        text-decoration-color: rgba($grey-53, 0.01);
        transition-property: text-decoration-color;
        transition-duration: 0.3s;
        
        &:hover {
            // .footer-contact__link:hover
            text-decoration-color: $grey-20;
        }
    }
}

.social-link {
    // LinkedIn, Twitter, Facebook & TikTok links.
    color: black;
    text-decoration: none;

    &:hover {
        // .social-link:hover
        text-decoration: none;

        .icon--linkedin  {color: #0077b5;}
        .icon--twitter   {color: #1da1f2;}
        .icon--instagram {color: #5851db;}
        .icon--facebook  {color: #3b5998;}
        .icon--tiktok    {color: black;}
    }
    
    @media (min-width: 850px) {
        display: inline-block;
        text-align: center;
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 1.2em;
    }

    @media #{$xxl} {
        height: 45px;
        width: 45px;
        line-height: 45px;
        font-size: 2em;
    }
    
    .icon {
        // .social-link .icon
        transition: color .2s ease;
    }
}

//==============================================================================
// Footer Copyright.
//==============================================================================

.footer-copyright {
    // Small text at the very foot of the page.
    text-align: center;
    margin: 0;
    
    @media #{$xl} {
        text-align: left;
    }
    
    &__line {
        // .footer-copyright__line
        white-space: nowrap;
    }
}
