@font-face {
	font-family: 'iconfont';
	src:url('/css/fonts/iconfont.eot?4ksyd9');
	src:url('/css/fonts/iconfont.eot?#iefix4ksyd9') format('embedded-opentype'),
		url('/css/fonts/iconfont.ttf?4ksyd9') format('truetype'),
		url('/css/fonts/iconfont.woff?4ksyd9') format('woff'),
		url('/css/fonts/iconfont.svg?4ksyd9#iconfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'iconfont';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon--cart:before {
    content: "\63";
}

.icon--cross:before {
    content: "\78";
}

.icon--add:before {
    content: "\2b";
}

.icon--star:before {
    content: "\e907";
}

.icon--filter:before {
    content: "\e901";
}

.icon--check:before {
    content: "\43";
}

.icon--download:before {
    content: "\64";
}

.icon--upload:before {
    content: "\e905";
}

.icon--triangle-down:before {
    content: "\44";
}

.icon--home:before {
    content: "\68";
}

.icon--sign-in:before {
    content: "\73";
}

.icon--exit:before {
    content: "\58";
}

.icon--grid:before {
    content: "\67";
}

.icon--twitter:before {
    content: "\74";
}

.icon--facebook:before {
    content: "\e908";
}

.icon--tiktok:before {
    content: "\e909";
}

.icon--info:before {
    content: "\e906";
}

.icon--instagram:before {
    content: "\ea92";
}

.icon--edit:before {
    content: "\45";
}

.icon--map:before {
    content: "\21";
}

.icon--print:before {
    content: "\50";
}

.icon--line-arrow-right-bold:before {
    content: "\e900";
}

.icon--line-arrow-left-bold:before {
    content: "\e902";
}

.icon--line-arrow-right:before {
    content: "\e903";
}

.icon--line-arrow-left:before {
    content: "\e904";
}

.icon--magnify:before {
    content: "\6d";
}

.icon--user:before {
    content: "\75";
}

.icon--arrow-left:before {
    content: "\3c";
}

.icon--arrow-down:before {
    content: "\76";
}

.icon--arrow-up:before {
    content: "\5e";
}

.icon--arrow-right:before {
    content: "\3e";
}

.icon--email:before {
    content: "\65";
}

.icon--menu:before, .icon--list::before {
    content: "\4d";
}

.icon--linkedin:before {
    content: "\6c";
}

.icon--id:before {
    content: "\e90a";
}

.icon--order:before {
    content: "\e90b";
}

.icon--cog:before {
    content: "\e90c";
}

.icon--box:before {
    content: "\e90d";
}

.icon--lock:before {
    content: "\4c";
}

.icon--question-circle:before {
    content: "\3f";
}

.icon--phone:before {
    content: "\70";
}
