//******************************************************************************
// Mega Menu
//
//******************************************************************************

.mega-menu {
    // Wrapper class for the entire mega menu.
    display: none;
    background: transparent;
    z-index: $z-mega-menu;
    position: relative;

    &.is-active {
        // .mega-menu.is-active
        display: block;
    }
    
    @media #{$xxl} {
        display: block;
        background: $grey-20;
        position: relative;
        z-index: $z-mega-menu-desktop;
    }
}

.container--mega-menu {
    // Width-constrained mega-menu content container.
    background: $grey-20;
    position: relative;

    @media #{$xxl} {
        display: flex;
        align-items: center;
        height: 60px;
    }
}

.mega-menu__dropdown {
    // A wrapper for second & third level menus
    background: $grey-90;
    color: $grey-60;
    
    @media #{$xxl} {
        z-index: $z-mega-menu-dropdown;
        display: none; // hidden by default.
        position: absolute; // prevents page content being shifted down.
        left: 0;
        right: 0;
        top: 60px;
        box-shadow: 0 8px 0px rgba($black, 0.1);
        grid-template-columns: 300px 1fr;
        grid-template-rows: auto;
        margin: 0 15px;

        &:hover,
        &.is-active {
            // .mega-menu__dropdown:hover
            // .mega-menu__dropdown.is-active
            // Show the dropdown
            display: grid;

            .no-cssgrid & {
                // Legacy browser support
                display: flex;
            }
        }
    }

    @media #{$xxxl} {
        // Remove "hover cancel" spacing at sides of dropdown menu on screens large enough to accomodate
        margin: 0;
    }    
}

// .mega-menu__dropdown-title {
//     // .mega-menu__dropdown-title
//     display: none;
    
//     @media #{$xxl} {
//         font-weight: 600;
//         padding: 20px 0 10px 20px;
//         color: $mla-purple;
//         display: block;
//     }
// }

.menu__link {
    // Every menu item in entire mega menu and product listings panels
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    min-height: $menu-item-height;
    line-height: $menu-item-height - 2;
    border-bottom: 1px solid rgba(white, 0.2);
    padding: 0 20px;
    cursor: pointer;
    position: relative;
    
    @media #{$xs} {
        line-height: 56px;
        min-height: 58px;
    }
    
    @media #{$xxl} {
        padding: 0;
        margin: 0 0 15px 0;
        min-height: auto;
        line-height: 1.125em;
    }
    
    &:hover {
        // .menu__link:hover
        color: $grey-20;
    }
}

.mega-menu .menu__link.menu__link--has-children {
    // Signifier to show that the menu item has children
    
    &:after {
        // .mega-menu .menu__link.menu__link--has-children:after
        // Square block positioned right with right-arrow icon
        display: block;
        width: $menu-item-height;
        font-family: 'iconfont';
        content: '\3e';
        text-align: center;
        position: absolute;
        top: 0;
        right:0;
        color: inherit;
        font-size: 20px;
        
        @media #{$xxl} {
            line-height: 0;
            content: none;
        }
    }
}

//==============================================================================
// TOP LEVEL MENU
//
// The main menu navbar (including Wiring & Lighting classes).
//
// mobile state:  stacked list
// desktop state: horizontal menu.
//==============================================================================


.mega-menu__top-level {
    // The top level nav elements (main menu & My Account).
    display: none;

    &.is-active {
        // .mega-menu__top-level.is-active
        display: block;
    }
    
    .menu__link {
        // .mega-menu__top-level .menu__link
        // Menu items in the top level.
        position: relative;
        color: $white;

        @media #{$xxl} {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            border: none;
            padding: 0 1.1vw;
            margin: 0;
            height: 60px;
            text-align: center;

            &:hover,
            &.is-active {
                // .mega-menu__top-level .menu__link:hover,
                // .mega-menu__top-level .menu__link.is-active
                // Used to change style when mega-menu dropdown for hovered item is visible.
                color: lighten($mla-purple, 35%);
            }

            &--active {
                // .mega-menu__top-level .menu__link--active
                // Used to change style when user is visiting the page for this menu item.
                color: lighten($mla-purple, 35%);
                text-decoration: underline;
                text-underline-offset: 0.3em;
                position: relative;
            }
        }
        
    }
    
    @media #{$xxl} {
        flex: 1 1 auto;
        display: inline-block;
        overflow: hidden;
        padding: 0;
        margin: 0 15px;
        
        .menu {
            // .mega-menu__top-level .menu
            // Top level menu layout
            display: flex;
        }
        
        .menu__item {
            // .mega-menu__top-level .menu__item
            // Individual items in top menu
            flex: 0 1 auto;
            text-align: center;
        }

        .menu__link.menu__link--has-children {
            // .mega-menu__top-level .menu__link.menu__link--has-children
            padding-right: 24px;
            
            &:after {
                // .mega-menu__top-level .menu__link.menu__link--has-children:after
                // Dropdown triggers in top menu (Product Categories & My Account)
                position: absolute;
                top: auto;
                right: 0;
                display: inline-block;
                margin-left: 5px;
                font-size: 14px;
                background: transparent;
                width: auto;
                content: '\76';
                text-transform: none;
            }
            
            &:hover:after,
            &.is-active:after {
                // .mega-menu__top-level .menu__link.menu__link--has-children:hover:after,
                // .mega-menu__top-level .menu__link.menu__link--has-children.is-active:after
                    content: '\5e'; // up arrow icon
            }
        }
    }

    @media #{$xxxl} {
        // Remove spacing at sides of top level menu to match dropdown
        margin: 0;
    }
}

//==============================================================================
// MOBILE LAYOUT-SPECIFIC COMPONENTS
//
// 2nd & 3rd level mobile-only.
//==============================================================================

.mega-menu__nav-header {
    // Mobile only container for menu title & back button
    // At the top of every 2nd/3rd level menu
    background-color: $grey-20;
    height: $menu-item-height;
    line-height: $menu-item-height;

    @media #{$xs} {
        line-height: 58px;
        height: 58px;
    }
    
    @media #{$xxl} {
        display: none;
    }
}

.mega-menu__nav-title {
    // Title at the top of every 2nd/3rd level menu
    text-transform: uppercase;
    color: $white;
    margin: 0 0 0 10px;
    font-size: 1em;
    letter-spacing: 0.6px;
    margin-left: 10px;
    
    @media #{$xxl} {
        margin: 0;
        padding-bottom: 15px;
        font-weight: bold;
        text-transform: none;
        font-size: 2em;
    }
}

.mega-menu__button-back {
    // Back button at the top of every 2nd/3rd level menu
    cursor: pointer;
    display: block;
    float: left;
    width: $menu-item-height;
    height: $menu-item-height;
    line-height: $menu-item-height - 2;
    text-align: center;

    &::after {
        font-family: 'iconfont';
        content: '\3c';
        text-align: center;
        color: lighten($mla-purple, 35%);
        font-size: 20px;
    }
    
    @media #{$xs} {
        width: 58px;
        line-height: 56px;
        height: 58px;
    }    
}

//==============================================================================
// SECOND LEVEL MENU
//
// List of Ranges
// 
//  mobile state:  stacked list
//  desktop state: stacked list inside dropdown, left aligned.
//==============================================================================

.mega-menu__second-level {
    // 2nd level nav elements
    display: none;

    @media #{$xxl} {
        flex: 1 1 240px;
        display: none;
        grid-column: 1 / 2;
        position: relative;
        
        &:after {
            // .mega-menu__second-level:after
            // Right hand single pixel border for nav menu
            content: '';
            display: block;
            height: calc(100% - 40px);
            width: 1px;
            background: white;
            position: absolute;
            top: 20px;
            right: 0;
        }

        .menu {
            // .mega-menu__second-level .menu
            padding: 0 0 10px 0;
        }

        .menu__link {
            // .mega-menu__second-level .menu__link
            // Individual link and hover styles
            font-weight: normal;

            &:hover,
            &.is-active {
                // .mega-menu__second-level .menu__link:hover
                // .mega-menu__second-level .menu__link.is-active
                 color: $grey-20;
                 
                    &:after {
                    // .mega-menu__second-level .menu__link:hover:after
                    // .mega-menu__second-level .menu__link.is-active:after
                    // right-arrow icon
                    position: static;
                    content: '\3e';
                    font-size: 16px;
                    margin-left: auto;
                    width: auto;
                    color: inherit;
                }
            }
        }
        
        .menu__link.menu__link--has-children {
            // .mega-menu__second-level .menu__link.menu__link--has-children
            // Overriding styles for left hand column links on desktop
            padding: 8px 20px;
            margin: 0;
            line-height: 1;
            border: none;
        }
    }

    &.is-active {
        // .mega-menu__second-level.is-active
        display: block;
    }
}

.mega-menu__second-level .menu__item:first-child .menu__link.menu__link--has-children {
    // Add padding to first 2nd level menu item.
    @media #{$xxl} {
        padding-top: 20px;
    }
}


//==============================================================================
// Third Level menu (categories).
//
// mobile state:  stacked list
// desktop state: info panel & flexible column menu items.
//==============================================================================

.mega-menu__third-level {
    // 3rd level nav elements
    display: none;
    
    &.is-active {
        // .mega-menu__third-level.is-active
        display: block;

        @media #{$xxl} {
            display: flex;
        }
    }
        
    @media #{$xxl} {
        flex: 1 1 100%;
        grid-column: 2 / 3;
        height: 100%;
        display: none;
        background: transparent;
        padding: 20px;

        .menu {
            // .mega-menu__third-level .menu
            // Right hand panel on dropdown
            padding: 0;
            display: block;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-column-gap: 15px;
        }

        .menu__item {
            // .mega-menu__third-level .menu__item
            // Individual menu item container
            display: inline-block;
        }

        .menu__link-text {
            // .mega-menu__third-level .menu__link-text
            // Paragraph text for third level menu buttons
            flex: 1 1 auto;
            @include line-clamp(2);
        }

        .menu__link {
            // .mega-menu__third-level .menu__link
            // Text link - using CSS Grid if supported
            display: flex;
            align-items: center;
            border: none;
            height: 30px;
            margin: 0;
            padding-right: 10px;
            
            .no-cssgrid & {
                // .mega-menu__third-level .menu__link .no-cssgrid
                width: 260px;
                margin-right: 15px;
            }
            
            &:hover {
                // .mega-menu__third-level .menu__link:hover
                color: $grey-20;
            }
        }
    }

    .menu {
        // .mega-menu__third-level .menu
        // Use 2 column layout.
        
        @media #{$lg} {
            grid-template-columns: repeat(2, 1fr);
            align-self: start;
        }
    }
}

.range-info {
    // Container for range information in 3rd level nav (desktop only)
    display: block;
    text-align: center;
    margin-top:20px;
    
    @media #{$xxl} {
        margin-top: 0;
        margin-right: 20px;
        border-right: 1px solid white;
        padding-right: 20px;
        text-align: left;
        width: 340px;
    }
}

.range-info__image {
    // Range thumbnail in 3rd level nav info
    padding: 20px;
    background: white;
    margin: 0 auto 20px auto;
    text-align: center;
    max-width: 340px;

    img {
        // Center align the large range info image
        display: block;
        margin: 0 auto;
    }
}

.range-info__view-all {
    // View All button, hidden on mobile by container
    width: auto;
    display: inline-block;
    background-color: transparent;
}

//==============================================================================
// My Account Menu.
//==============================================================================

.my-account-header-menu {
    // 'My Account' in site header.
    text-align: center;
    
    @media #{$xxl} {
        flex: 0 1 auto;
        position: relative;
        overflow: visible;
        text-align: center;

        &:hover,
        &:focus-within {
            // .my-account-header-menu:hover
            // .my-account-header-menu:focus-within
            background: $grey-20;
            
            .my-account-header-menu__dropdown {
                // .my-account-header-menu:hover .my-account-header-menu__dropdown
                // .my-account-header-menu:focus-within .my-account-header-menu__dropdown
                display: flex;
            }

            .menu__link--has-children:after {
                // .my-account-header-menu:hover .menu__link--has-children:after
                // .my-account-header-menu:focus-within .menu__link--has-children:after
                color: $grey-78;
            }
        }
    }
 
    &__dropdown.menu {
        // .my-account-header-menu__dropdown.menu
        // Menu hidden by default, visible on hover.
        
        @media #{$xxl} {
            flex-direction: column;
            display: none;
            position: absolute;
            background: $grey-20;
            width: 100%;
            
            .menu__link {
                // .my-account-header-menu__dropdown.menu .menu__link
                justify-content: flex-start;
                color: white;
                
                &:hover {
                    // .my-account-header-menu__dropdown.menu .menu__link
                    background: $grey-30;
                }
            }
        }
    }
   
    &__button {
        // .my-account-header-menu__button
        // 'Sign In' button.
        width: auto;
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        
        @media #{$less-than-xxl} {
            margin: 20px;
        }
        
    }
}

// //==============================================================================
// // ROOM BY ROOM MENU.
// //==============================================================================

// .room-menu {
//     grid-column: 1 / -1;
//     padding: 0;
// }

// .room-menu-grid {
//     padding: 20px;
//     grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
//     grid-column-gap: 10px;
//     display: grid;
// }


// //==============================================================================
// // ABOUT US MENU.
// //==============================================================================

// .about-us-menu {
    
//     &.is-active {
        
//     }
// }
