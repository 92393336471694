/******************************************************************************
 * NotificationMessage UI component.
 *
 *******************************************************************************/

.notification-message {
    display: grid;
    width: 100%;
    transition-property: grid-template-rows, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    grid-template-rows: 1fr;
    align-self: start;
}

.notification-message-content {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #d9edf7;
    border: 2px solid #80d3e4;
    border-radius: 4px;
    -webkit-user-select: none;
    user-select: none;
    transition-property: opacity, padding-top, padding-bottom, margin-top, margin-bottom, border-top-width, border-bottom-width;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    margin-bottom: 10px;
    margin: 0 0 10px 0;
    padding: 10px 20px;
}

.notification-message-close:after {
    /* Cross icon. */
    font-family: "iconfont", sans-serif;
    font-size: 20px;
    content: "\00d7";
    line-height: 1;
    cursor: pointer;
    padding-left: 5px;
    margin-left: auto;
}

.notification-message-text {
    /* Container for text content */
    flex-basis: 100%;
    font-size: 1em;
    line-height: 1.3;
    text-align: left;
    overflow: hidden;
}

.notification-message-text p {
    margin-top: 0;
    margin-bottom: 10px;
}

.notification-message-text ul {
    list-style-position: outside;
    margin-bottom: 10px;
    padding: 0;
}

.notification-message-text li {
    margin-left: 40px;
}

.notification-message-text :last-child {
    margin-bottom: 0;
}


/* HIDDEN STATE
   --------------------------------------------------------------------------
*/

.notification-message.is-hidden {
    grid-template-rows: 0fr;
    opacity: 0;
}

.notification-message.is-hidden .notification-message-content {
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
}

/* MODIFIERS
   --------------------------------------------------------------------------
*/

/* Alert message (yellow) */
.notification-message.alert .notification-message-content {
    color: #454500;
    background-color: #fcf8e3;
    border-color: #f9d180;
}

/* Error message (red) */
.notification-message.error .notification-message-content {
    color: #660000;
    background-color: #f2dede;
    border-color: #990000;
}