// ************************************************************
// Buttons.
// 
// ************************************************************

.button {
    // Base button class
    // White background, with uppercase purple text and a purple border
    @extend %text-truncated;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0 30px;
    background-color: transparent;
    color: $mla-purple;
    border: 1px solid $mla-purple;
    height: $button-height;
    line-height: $button-height;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s background-color;
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
    font-variant: all-small-caps;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1.3px;
    
    .icon {
        // Icons in buttons position:absolute to center-align button text
        line-height: inherit;
        padding: 0 1em;
        
        &.pull-left,
        &.pull-right {
            position: absolute;
        }

        &.pull-left {
            left: 0;
        }

        &.pull-right {
            right: 0;
        }

        &:before {
            // Sets iconfont to display block so icons in buttons can be positioned accurately
            display: block;
        }
        
    }

    &:hover {
        background-color: $mla-purple;
        color: white;
    }

    //==============================================================================
    // Secondary Button
    //
    // Purple background with white text and no border
    //==============================================================================

    &--secondary {
        // .button--secondary
        background-color: $mla-purple;
        color: $white;
        border: none;
        
        &:hover {
            background: darken($mla-purple, 5%);
            color: $white;
        }
    }

    //==============================================================================
    // Rounded Button.
    //==============================================================================

    &--rounded {
        // .button--rounded
        border-radius: 8px;
    }


    //==============================================================================
    // Colured Buttons.
    //==============================================================================

    &--blueberry {
        // .button--blueberry
        background-color: $color-blueberry;
        color: white;
        border: none;

        &:hover {
            background-color: darken($color-blueberry, 5%);
            color: white;
        }
    }
    
    &--oxford-blue {
        // .button--oxford-blue
        background-color: $color-oxford-blue;
        color: white;
        border: none;

        &:hover {
            background-color: darken($color-oxford-blue, 5%);
            color: white;
        }
    }


    //==============================================================================
    // Small Button
    //
    // Reduced height button with reduced font size
    //==============================================================================
    
    &--small {
        // .button--small
        font-size: 0.875rem;
        height: 30px;
        line-height: 28px;
        width: auto;
        padding: 0 15px;
        
        .icon {
            line-height: inherit;
            font-size: 12px;
            padding: 0;

            &:before {
                width: 30px;
                height: 30px;
            }
            
        }
    }

    //==============================================================================
    // Link Button
    //
    // Small button without borders to mimic text link
    //==============================================================================
    
    &--link {
        // .button--link
        background: transparent;
        width: auto;
        padding: 0;
        border: none;
        text-decoration: underline;

        &:hover, &active, &:focus {
            background: transparent;
            color: $mla-purple;
        }
    }
    
    //==============================================================================
    // Medium Button
    //
    // Reduced height button to match input fields, typically in filters
    //==============================================================================
    
    &--medium {
        // .button--medium
        font-size: 1rem;
        height: 45px;
        line-height: 42px;
        padding: 0 15px;
        
        .icon {
            line-height: inherit;
            font-size: inherit;
            padding: 0;

            &:before {
                width: 45px;
                height: 45px;
            }
            
        }
    }
        
    //==============================================================================
    // Inline Button
    //
    // Inline button with auto width on desktop
    //==============================================================================

    &--inline {
        // .button--inline
        width: 100%;

        @media #{$desktop} {
            width: auto;
        }
    }

    //==============================================================================
    // Button has icon
    //
    // Right hand padding to accomodate icon placement
    //==============================================================================
    
    &--has-icon {
        // .button--has-icon
        padding-right: 60px;

        &.button--small {
            // Reduced padding on small buttons that have icons
            padding-right: 35px;
        }

        &.button--medium {
            // Reduced padding for medium buttons with icons
            padding-right: 45px;
        }
    }

    //==============================================================================
    // Button has icon (left)
    //
    // Left hand padding to accomodate icon placement
    //==============================================================================
    
    &--has-icon-left {
        // .button--has-icon-left
        padding-left: 60px;

        &.button--small {
            // Reduced padding on small buttons that have icons
            padding-left: 35px;
        }
    }
        
}

@mixin button--small {
    // Include within rules for a button to make it smaller.
    // Usually within a media query (otherwise,
    // consider class .button--small).
    font-size: 0.875em;
    height: 30px;
    line-height: 30px;
    width: auto;
    padding: 0 15px;
    
    .icon {
        line-height: inherit;
        font-size: 12px;
        padding: 0;

        &:before {
            width: 30px;
            height: 30px;
        }
    }
}

//==============================================================================
// Button: Disabled
//
// Greyed out disabled button state
//==============================================================================
    
.button[disabled] {
    // Disabled button element
    background: $grey-90;
    border: 1px solid $grey-60;
    color: $grey-40;
    cursor: not-allowed;
}
