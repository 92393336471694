@import "tools.vars";
@import "tools.mixins";

.tooltip {
    z-index: $z-tooltip;
    position: absolute;
    left: -500px;
    top: -500px;
    display: none;
    max-width: 280px;
    min-width: 50px;
    direction: ltr;
    box-shadow: none;
    pointer-events: none;
    user-select: none;
    padding: 0.2em 1em;
    font-size: 90%;
    line-height: 1.3;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    transition: top 0.1s ease;
    animation: tooltip-fadein 0.3s ease-in-out;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.tooltip-content {
    position: relative;
    padding: 5px 9px;
    overflow: hidden;
    text-align: left;
    word-wrap: break-word;
}

@keyframes tooltip-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}