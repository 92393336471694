// *************************************************************
// Page Header
//
// Header background & container definition
// Grid elements for Knightsbridge logo, contact and search boxes
// and basket summary
//
// *************************************************************

.header {
    // Site-wide page header container <header> element.
    
    @media #{$mobile} {
        display: grid;
        grid-template-columns: 90px 1fr 90px;
        grid-template-rows: auto;
        z-index: $z-header;
        position: relative;
        align-items: center;
    }
    
    @media #{$desktop} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 118px;
    }
    
    @media #{$less-than-xxl} {
        border-bottom: 1px solid rgba($grey-20, 0.2);
    }    
}


// ==============================================================================
// Hamburger Menu Button
// ==============================================================================

.header-menu-button-container {
    // Mobile Menu Toggle Container
    grid-column: 1 / 2;
    align-self: center;
    justify-self: center;
    
    @media #{$desktop} {
        padding-left: 20px;
    }
    
    @media #{$xxl} {
        display: none;
    }
}

.header-menu-button {
    // Mobile Menu Toggle Button - Clickable Area
    @include user-select(none);
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    &.is-active {
        // Active state for mobile menu button when open
        .header-menu-button__content {
            border-color: $mla-purple-lighten;

            .header-menu-button__icon {
                &:before {
                    content: "x";
                }
            }
        }
    }    

    &__icon {
        // Mobile Menu hamburger icon
        font-size: 17px;
        display: block;
        color: $mla-purple;
        
        @media #{$desktop} {
            font-size: 24px;
        }
    }
}

// ==============================================================================
// Knightsbridge logo
// ==============================================================================

.header-logo {
    // Knightsbridge Logo
    flex: 1 1 200px;
    background-image: url('/images/knightsbridge-logo.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 33px;
    
    @media #{$mobile} {
        grid-column: 2 / 3;
        margin: 15px 0;
    }
    
    @media #{$desktop} {
        margin: 0 20px;
        grid-column: 1 / 2;
        max-width: 200px;
    }

    @media #{$xxxl} {
        margin: 0;
    }
}

// ==============================================================================
// Search Bar.
// 
// 
// ==============================================================================

.header-main {
    // Container for search bar
    display: flex;
    flex: 1 1 auto;
    margin: 0 10px 10px 10px;
    
    @media #{$mobile} {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
    }
    
    @media #{$desktop} {
        margin: 0;
        flex: 0 1 600px;
        width: 100%;
        max-width: 600px;
    }
}

.header-search {
    // Search container
    display: flex;
    flex: 1 1 auto;
    
    &:focus-within {
        outline: 1px solid $grey-40;
    }
}

.header-search__input {
    // Full width (minus submit button) text field for Search
    height: 40px;
    line-height: initial;
    width: calc(100% - 40px);
    display: inline-block;
    border: none;
    vertical-align: top;
    font-family: $base-font;
    font-size: 1rem;
    padding: 0 15px;
    border-radius: 0;
    background: $grey-92;
    color: $mla-purple;
    caret-color: $mla-purple;
    
    &::placeholder {
        color: $grey-53;
    }
    
    &:focus {
        outline: none;
    }
    
    @media #{$desktop} {
        font-size: 0.875rem;
    }

}

.header-search__submit {
    // Submit button for Search
    width: 40px;
    height: 40px;
    display: inline-block;
    font-size: 1rem;
    text-align: center;
    color: $white;
    background: $grey-53;
    vertical-align: top;
    cursor: pointer;
    transition: 0.3s background;
    
    &:hover {
        background: $mla-purple;
    }
    
    &:focus {
        outline: 2px solid rgba($mla-purple, 0.5);
    }
}


// ==============================================================================
// 'Contact Us' button
// 
// Link to Contact page in website header (visible on desktop only)
// ==============================================================================

.header-contact {
    display: none;
    flex: 0 0 160px;
    font-size: 14px;
    margin: 0 30px;
    
    @media #{$desktop} {
        display: block;
    }
}

// ==============================================================================
// Basket summary widget
// 
// Shows order type, number, basket icon, number of products and total cost.
// ==============================================================================

.header-basket-container {
    // Shopping Basket Shortcut and Summary
    
    @media #{$mobile} {
        grid-column: 3 / 4;
    }

    @media #{$desktop} {
        flex: 0 1 120px;
        padding-right: 20px;
    }
    
    @media #{$xxxl} {
        padding-right: 0;
    }
}

.header-basket {
    // Shopping Basket Shortcut and Summary
    display: flex;
    align-items: center;
    justify-content: center;
    color: $mla-purple;
    text-decoration: none;
    transition-duration: 0.3s;
    cursor: pointer;

    // .header-basket__order-type {
    //     // Order Type text line
    //     color: $grey-53;
    //     display: block;
    //     font-size: 0.625em;
    //     margin-top: 6px;

    //     @media #{$xl} {
    //         font-size: 1.063em;
    //         margin-top: 12px;
    //     }
    // }

    // .header-basket__order-number {
    //     // Order Number text line - hidden on mobile
    //     color: $mla-purple;
    //     font-size: 0.750em;
    //     display: none;

    //     @media #{$desktop} {
    //         display: block;
    //     }

    //     @media #{$xl} {
    //         font-size: 1.063em;
    //     }
    // }

    &__icon {
        // Basket icon and surround, also serves as container for item count label
        font-size: 17px;
        font-family: $base-font;
        line-height: 40px;
        text-align: center;
        color: $mla-purple;
        position: relative;
        
        @media #{$desktop} {
            margin-right: 20px;
        }

        @media #{$xl} {
            line-height: 64px;
            font-size: 26px;
        }
    }

    &__item-count {
        // Floating item count label
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: $grey-96;
        border-radius: 50%;
        color: $grey-20;
        position: absolute;
        top: -4px;
        right: -16px;
        font-size: 0.750rem;

    }

    &__total {
        // Basket summary subtotal
        display: none;
        
        @media #{$desktop} {
            display: block;
            font-size: 0.750rem;
        }
    }
}

//==============================================================================
// Listings header.
//
// Secondary page-header containing breadcrumbs and view toggle button.
//==============================================================================

.header-listings {
    // <header> element at the top of every listings page containing breadcrumbs
    // and view-toggle button.
    background-color: $white;
    line-height: 50px;
    height: 50px;
    position: relative;
    border-bottom: 1px solid $grey-78;
    padding-left: 15px;
    padding-right: 15px;
    z-index: $z-header-listings;

    > .container {
        // .header-listings > .container
        // Ensure that absolute-positioned toggle button is constrained to
        // the container's extremites.

        @media #{$desktop} {
            position: relative;
        }
    }
}



//==============================================================================
// Breadcrumbs.
//
// Navigation in header that provides hierarchy between content.
//==============================================================================

.breadcrumbs {
    // Breadcrumb navigation <ul> element.
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    letter-spacing: 0.05em;
    font-size: 12px;

    //------------------------------------------------------------------------------
    // Breadcrumb menu item.
    //-----
    &__item {
        // .breadcrumbs__item
        // Breadcrumb <li> element.
        display: inline-block;

        @media #{$mobile} {
            &:before {
                // .breadcrumbs__item:before
                // Pseudo-element displaying a chevron divider icon between breadcrumb items.
                display: inline-block;
                font-family: 'iconfont';
                content: "\3c"; // arrow-left icon
                color: $mla-purple;
                padding: 0 5px;
                position: relative;
                top: 2px;
            }            
        }

        @media #{$desktop} {
            color: $grey-20;

            &:after {
                // .breadcrumbs__item:after
                // Pseudo-element displaying a chevron divider icon between breadcrumb items.
                display: inline-block;
                content: '/';
                color: $grey-78;
                padding: 0 5px;
            }
        }
        
        &:last-of-type {
            // .breadcrumbs__item:last-of-type
            // In mobile-view, only show the first breadcrumb node.
            @media #{$mobile} {
                display: none;
            }

            &:after {
                // .breadcrumbs__item:last-of-type:after
                // Don't display the chevron for the deepest breadcrumb node.
                content: '';
            }
        }
    }

    //------------------------------------------------------------------------------
    // Breadcrumb link.
    //-----

    &__link {
        // .breadcrumbs__link
        // Breadcrumb hyperlink inside the <li>
        color: $mla-purple;
        text-decoration: none;

        @media #{$desktop} {
            color: $grey-60;
        }

        &:hover {
            // .breadcrumbs__link:hover
            text-decoration: underline;
        }
    }
}

//==============================================================================
// Grid/List View Toggle Control.
//
// Button in the header breadcrumb bar that allows switching between grid &
// list views.
//==============================================================================

.button__view-toggle {
    // Square button in the header that toggles between Grid & List view
    background-color: white;
    color: $mla-purple;
    height: 49px;
    line-height: 49px;
    width: 49px;
    display: block;    
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    text-align: center;
    font-size: 1.2em;
    border-width: 0 0 0 1px;
    border-style: none solid;
    border-color: transparent $grey-87;

    @media #{$desktop} {
        border-right-width: 1px;
    }

    &:hover {
        // .button__view-toggle:hover
        background-color: $grey-98;
        color: darken($mla-purple, 2%);
    }

    .text {
        // .button__view-toggle .text
        // Hidden text label.
        display: none;
    }

    .icon {
        // .button__view-toggle .icon
        // Targets the list and grid view icons.
        position:relative;

        &--menu {
            // .button__view-toggle .icon--menu
            // Adjust vertical position of menu item.
            top: 2px;
        }

        &--grid {
            // .button__view-toggle .icon--grid
            // Adjust vertical position of grid item.
            top: 4px;
        }
    }
}
