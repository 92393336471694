//******************************************************************************
// Message Bar.
//
// Message bar that appears at the top of all pages.
//******************************************************************************

// Non-rendering library SASS.
@import "tools.vars";
@import "tools.mixins";


.message-bar {
    background-color: $grey-92;
    color: $grey-60;
    height: 50px;

    > .container {
        max-width: 650px;
        position: relative;

        @media #{$less-than-sm} {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .js-message-bar {
        &.is-loading {
            visibility: hidden;
        }
    }

    &__message {
        // .message-bar__message
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        min-height: 50px;
        color: $grey-60;

        &:hover,
        &:focus {
            // .message-bar__message:hover
            color: $mla-purple;
            outline: none;
        }
    }

    .slider-nav-button {
        background-color: $grey-92;

        &:hover {
            background-color: $grey-90;
        }

        @media #{$less-than-sm} {
            display: none;
        }
    }


}