// *****************************************************************************
// Helpers.
//
// Utility classes etc.
// *****************************************************************************

// Non-rendering library SASS.
@import 'tools.vars';
@import 'tools.mixins';

// =============================================================================
// Position helper classes.
// =============================================================================

.pull-left {
    // Float left.
    float: left !important;
}

.pull-right {
    // Float right.
    float: right !important;
}

.centered {
    // Centre horizontally.
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    // Hide / remove from DOM.
    display: none !important;
}

.visually-hidden {
    // Visually hidden (legacy / not used?).
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

//------------------------------------------------------------------------------
// Show / hide responsive helper classes.
//-----

.desktop-only {
    // Hide for viewport widths < 'desktop' breakpoint.

    @media #{$mobile} {
        display: none !important;
    }
}

.mobile-only {
    // Hide for viewport widths >= 'desktop' breakpoint.

    @media #{$desktop} {
        display: none !important;
    }
}

.hide-lt-sm {
    // Hide for viewport widths < 'sm' breakpoint.

    @media #{$less-than-sm} {
        display: none !important;
    }
}

.hide-lt-md {
    // Hide for viewport widths < 'md' breakpoint.

    @media #{$less-than-md} {
        display: none !important;
    }
}

.hide-lt-lg {
    // Hide for viewport widths < 'lg' breakpoint.

    @media #{$less-than-lg} {
        display: none !important;
    }
}

.hide-lt-xl {
    // Hide for viewport widths < 'xl' breakpoint.

    @media #{$less-than-xl} {
        display: none !important;
    }
}

.hide-lt-xxl {
    // Hide for viewport widths < 'xxl' breakpoint.

    @media #{$less-than-xxl} {
        display: none !important;
    }
}

.hide-lt-xxxl {
    // Hide for viewport widths < 'xxxl' breakpoint.

    @media #{$less-than-xxxl} {
        display: none !important;
    }
}


// =============================================================================
// Loading spinner.
// =============================================================================

.spinner {
    // Animated loading spinner.
    display: inline-block;
    height: 15px;
    width: 15px;
    border: 2px solid rgba(#000, 0.1);
    border-top-color: rgba(#000, 0.3);
    border-radius: 50%;
    animation: rotate 0.6s infinite linear 0.25s;
    opacity: 0;
    align-self: center;
    justify-self: center;
}

@keyframes rotate {
    from {
        opacity: 1;
        transform: rotate(0deg);
    }

    to {
        opacity: 1;
        transform: rotate(359deg);
    }
}

//==============================================================================
// Template.
//==============================================================================

.template {
    // A substitute for the <template> element.
    display: none !important;
}

//==============================================================================
// Text formatting.
//
// Can be applied to inline text wrapped in <span> tags or other elements
// (e.g. table cells).
//==============================================================================

.text-normal {
    // .text-normal
    // Normal weighted font text style.
    font-weight: normal !important;
}

.text-bold {
    // .text-normal
    // Bold weight text style.
    font-weight: bold !important;
}

.text-underline {
    // .text-underline
    // Underlined text style.
    text-decoration: underline !important;
}

.text-left {
    // .text-left
    // Left-aligned text.
    text-align: left !important;
}

.text-center {
    // .text-center
    // Center-aligned text.
    text-align: center !important;
}

.text-right {
    // .text-right
    // Right-aligned text.
    text-align: right !important;
}

.text-nowrap {
    // .text-nowrap
    // Prevent text wrapping.
    white-space: nowrap !important;
}

.text-small {
    // .text-small
    // Reduces font size.
    font-size: 75% !important;
}

.text-muted {
    // .text-muted
    // Makes text muted/grey.
    color: $grey-53 !important;
}

.text-positive {
    // .text-positive
    // Makes text green.
    color: $color-positive !important;
}

.text-red {
    // .text-red
    // Makes text red.
    color: $color-negative !important;
}

.text-blue {
    // Blue coloured text.
    color: $blue !important;
}

.text-blueberry {
    // Blueberry coloured text.
    color: $color-blueberry !important;
}


//------------------------------------------------------------------------------
// Vertical Alignment.
//-----

.align-top {
    // Vertically Align to top.
    vertical-align: top !important;
}


//------------------------------------------------------------------------------
// Line Height.
//-----

.leading-none {
    line-height: 1 !important;
}

.leading-tight {
    line-height: 1.25 !important;
}

.leading-snug {
    line-height: 1.375 !important;
}

.leading-normal {
    line-height: 1.5 !important;
}

// .leading-relaxed {
//     line-height: 1.625!important;
// }

// .leading-loose {
//     line-height: 2!important;
// }



//------------------------------------------------------------------------------
// Background colour.
//-----


.bg-grey-98 {
    background-color: $grey-98;
}

.bg-grey-96 {
    background-color: $grey-96;
}


//==============================================================================
// Debug Class.
//
// Using Developer tools, add the debug class to the HTML element to display the
// current media-queries in DOM.
//==============================================================================

.debug {
    // Add the debug class to the html element to display the current media-query in DOM

    &::before {
        position: fixed;
        content: 'mobile';
        bottom: 0px;
        right: 0px;
        padding: 0 10px;
        background: rgba(red, .5);
        display: inline-block;
        height: 30px;
        line-height: 30px;
        color: $white;
        z-index: $z-debug;
        font-size: 11px;

        @media #{$xs} {
            content: 'xs'
        }

        @media #{$sm} {
            content: 'sm'
        }

        @media #{$desktop} {
            content: 'desktop'
        }

        @media #{$md} {
            content: 'md'
        }

        @media #{$lg} {
            content: 'lg'
        }

        @media #{$xl} {
            content: 'xl'
        }

        @media #{$xxl} {
            content: 'xxl'
        }

        @media #{$xxxl} {
            content: 'xxxl'
        }
    }
}

//==============================================================================
// Spacing Utility Classes.
//
// Derived from Bootstrap 4 spacing utility classes
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/utilities/_spacing.scss
//
// Usage:
//
// Class names can be built up with the following segments:
// [m|p]-[a|t|r|b|l|x|y]-[1|2|3]
//
//
// m = margin
// p = padding
//
// a = top, right, bottom + left
// t = top
// r = right
// b = bottom
// l = left
// x = left + right
// y = top + bottom
//
// 1 = 10px
// 2 = 15px
// 3 = 20px
//
// Examples:
//
// m-t-2 = margin-top: 15px
// p-y-3 = padding-top: 20px + padding-bottom: 20px
//==============================================================================

// Used by the spacing utility classes in _tools.helpers.scss
$spacer: 15px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

$spacers: (
    0: (x: 0,
        y: 0),
    1: (x: $spacer-x,
        y: $spacer-y ),
    2: (x: ($spacer-x * 1.5),
        y: ($spacer-y * 1.5)),
    3: (x: ($spacer-x * 2),
        y: ($spacer-y * 2))) !default;

//------------------------------------------------------------------------------
// Width.
//-----
.w-100 {
    width: 100% !important;
}

.w-600 {
    max-width: 600px !important;
}

.w-700 {
    max-width: 700px !important;
}

.w-800 {
    max-width: 800px !important;
}

.w-1000 {
    max-width: 1000px !important;
}

.w-1200 {
    max-width: 1200px !important;
}

.w-1400 {
    max-width: 1400px !important;
}

//------------------------------------------------------------------------------
// Margin and Padding.
.m-x-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.m-l-auto {
    margin-left: auto !important;
}

.m-r-auto {
    margin-right: auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
        $length-x: map-get($lengths, x
        );
    $length-y: map-get($lengths, y);

    .#{$abbrev}-a-#{$size} {
        #{$prop}: $length-y $length-x !important;
    }

    // a = All sides
    .#{$abbrev}-t-#{$size} {
        #{$prop}-top: $length-y !important;
    }

    .#{$abbrev}-r-#{$size} {
        #{$prop}-right: $length-x !important;
    }

    .#{$abbrev}-b-#{$size} {
        #{$prop}-bottom: $length-y !important;
    }

    .#{$abbrev}-l-#{$size} {
        #{$prop}-left: $length-x !important;
    }

    // Axes
    .#{$abbrev}-x-#{$size} {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
    }

    .#{$abbrev}-y-#{$size} {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
    }
}
}

//------------------------------------------------------------------------------
// Positioning.
//-----
.pos-f-t {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-debug;
}

//==============================================================================
// Demoswap.
//
//
//==============================================================================
.demoswap {
    // Select field with options to view template states, fixed to bottom corner.
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    padding: 5px;
    background-color: rgba(black, 0.3);
    color: white;
    line-height: 1.5;
    border-radius: 0 8px 0 0;

    > label {
        // .demoswap > label
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    > select {
        // .demoswap > select
        font-size: 16px;
        height: auto;
    }
}

//==============================================================================
// Sudo.
//==============================================================================

.sudo {
    // Fixed position indicator shown when signed in as another user.
    display: inline-block;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px 10px;
    background-color: $mla-purple;
    color: white;
    line-height: 1.5;
    border-radius: 0 0 8px 8px;
    width: fit-content;
    white-space: nowrap;
}