
@import "tools.vars";
@import "tools.mixins";

.cookies-dialog {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    background: $grey-23;
    border-radius: 10px;
    color: $grey-78;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 30vw;
    z-index: $z-cookies-dialog;

    &--hidden {
        display: none;
    }

    @media #{$less-than-md} {
        max-width: 50vw;
    }

    &__text {
        line-height: 1.4;
        order: 1;

        @media #{$sm} {
            flex-basis: calc(100% - 30px);
        }
    }

    &__policy-link {
        color: white;
        line-height: 30px;

        @media #{$less-than-sm} {
            flex-basis: 100%;
            order: 2;
        }
        @media #{$sm} {
            order: 3;
        }
    }

    &__close {
        line-height: 30px;
        font-size: 1.5em;
        cursor: pointer;

        order: 3;
        @media #{$sm} {
            order: 2;
        }
    }

    &__accept {
        order: 4;
    }
}