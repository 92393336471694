// *****************************************************************************
// Form UI Library
//
// Contains sitewide validation, message, form input and button formats
// *****************************************************************************

// Non-rendering library SASS.
@import 'tools.vars';
@import 'tools.mixins';

//==============================================================================
// Validation plugin message notifications.
//
// Message area notifications to provide feedback via the validation plugin when
// user submits a form.
//==============================================================================

.message-area {
    // Message area <div> wrapper.
    padding: 15px;
    margin: 15px 0;
    color: $white;
    text-align: left;
    position: relative;

    &__close,
    > .icon.icon--cross {
        // .message-area__close, (.message-area > .icon.icon--cross to be deprecated)
        // Close icon, positioned in right-hand corner.
        position: absolute;
        top: 23px;
        right: 23px;
        font-size: 12px;
    }

    &__content,
    > .message-content {
        // .message-area__content (.message-area > .message-content to be deprecated)
        // Text content of message area.
        display: inline-block;
        max-width: calc(100% - 40px);

        p {
            margin-bottom: 15px;
        }
        
        ol, ul {
            // .message-area__content ol, .message-area__content ul
            // Message area lists
            list-style-position: inside;
            margin-bottom: 15px;            
        }

        ul {
            // .message-area__content ul
            // Message area unordered list
            list-style-type: disc;
        }
        
        li {
            // .message-area__content li
            // Message area list items
            margin-left: 15px;
            margin-bottom: 5px;
            line-height: 1.3;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    //------------------------------------------------------------------------------
    // Modifier states.
    //
    //-----

    &.alert,
    &--alert {
        // Invalid input / other alert.
        background: $mla-purple;
    }
    
    &.error,
    &--error {
        // Error message
        background: #f2dede;
        color: #a94442;
        border: 1px solid #ebccd1;
    }

    &.notify,
    &--notify {
        // Informational message
        background: $grey-40;
    }
}


// -----------------------------------------------------------------------------
// Default styles for form fields
// -----

.field {
    // Container for all form input fields
    width: 100%;
    margin-bottom: 15px;
    
    &__label {
        // .field__label
        display: block;
        font-weight: bold;
        margin-bottom: 2px;
        width: 100%;
        font-size: 1rem;
        line-height: 1.3;
    }
    
    &__input {
        // .field__input
        display: block;
        width: 100%;
        padding: 0 12px;
        font-size: 1rem;
        border-radius: 0;
        background-color: $white;
        border: 1px solid $grey-78;
        transition: all 0.15s ease-in-out;

        // Reduce appearance of placeholder text...
        &::-webkit-input-placeholder {color: rgba(black, 0.2)}
        &::-moz-placeholder          {color: rgba(black, 0.2)}
        &::-moz-placeholder          {color: rgba(black, 0.2)}
        &:-ms-input-placeholder      {color: rgba(black, 0.2)}

        &[type="text"] {
            // .field__input[type="text"]
            // Remove drop shadow and rounded corner on older iOS versions.
            -webkit-appearance: none;
        }

        &:focus {
            // .field__input:focus
            outline: none;
            border-color: $mla-purple;
            box-shadow: 0 0 0 1px $mla-purple;
        }

        .bg--grey & {
            // .bg--grey .field__input          
            // Amend input styles if an ancestor has bg--grey class
            border-color: $grey-60;
            border-radius: 1px;

            &:focus {
                // .bg--grey .field__input:focus
                border-color: $mla-purple;
            }
        }

        &.invalid {
            // .field__input.invalid
            border-color: $color-negative;
            box-shadow: 0 0 0 1px $color-negative;
        }
    }

    &__comment {
        // .field__comment
        margin-top: 4px;
        color: $grey-60;
    }
}

.field--text-inline {
    // Inline text and label - side by side
    
    @media #{$desktop} {
        // Inline overrides apply to desktop only
        // All mobile elements are block
        .field__label {
            // Field Label on left
            display: inline-block;
            width: auto;
            margin-right: 15px;
            margin-bottom: 0;
        }

        .field__input {
            // Field input in middle
            display: inline-block;
            width: auto;
        }

        .field__comment {
            // Field comment on right
            display: inline-block;
            margin-left: 15px;
        }
    }
}

.field--textarea {
    // Styles for text area
    .field__input {
        // Set default height and only allow vertical resizing
        height: 120px;
        font-family: $base-font;
        resize: vertical;
        padding: 10px 12px;
    }
   
}

.field--select {
    // Styles for select / combo box
    position: relative;

    &:after {
        content: '';
        display: block;
        user-select: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 45px;
        height: 45px;
        background-position: center center;
        background-repeat: no-repeat;
        pointer-events: none;
        background-image: url(/images/dropdown-arrow-down-dark.svg);
    }

    .field__input {
        font-family: $base-font;
        font-size: 1rem;
        appearance: none;
    }
        
}

.field--disabled {
    // Additonal styles to apply when disabling a field
    opacity: 0.5;
}

.field--radio,
.field--check {
    // Shared styles for radio buttons and checkboxes
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px 0;
    
    .field__input {
        // Radio button / Checkbox
        flex: 0 0 30px;
        display: inline-block;
        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;

        &:focus {
            // .field--radio .field__input:focus
            // .field--check .field__input:focus
            // Turn off all highlighting for radio / check controls
            outline: none;
            border: none;
            box-shadow: none;
        }
    }

    .field__label {
        // Label for each radio button / checkbox
        flex: 1 1 calc(100% - 30px);
        display: inline-block;
        width: auto;
        margin: 0;
    }
    
}

.field--no-stack {
    // Display radio buttons / checkboxes side-by-side rather than in a stacked list
    @media #{$desktop} {
        display: block;

        .field__input {
            // Add spacing between input and label
            margin-right: 15px;
        }

        .field__label {
            // Add spacing between label and next input
            margin-right: 30px;
        }        
    }
}

//------------------------------------------------------------------------------
// Fieldset.
//---------

.fieldset {
    // Wrapper for a set of .field components
    display: flex;
    flex-wrap: wrap;
    margin: -8px;

    .field {
        // .fieldset .field
        padding: 8px;
        margin: 0 0 0 0;

        //----------------------------------------------------------------------
        // Field - Grid System.
        //
        // Set of grid modifier classes for the .field component.
        //---------
        @media #{$desktop} {
            // &--sixth {
            //     // .fieldset .field--sixth
            //     width: 16.666666666%; // 1 / 6 * 100%
            // }

            // &--five-sixths {
            //     // .fieldset .field--five-sixths
            //     width: 83333333333%; // 5 / 6 * 100%
            // }
            
            &--fifth {
                // .fieldset .field--fifth
                width: 20%;
            }

            &--two-fifths {
                // .fieldset .field--two-fifths
                width: 40%;
            }

            // &--four-fifths {
            //     // .fieldset .field--four-fifths
            //     width: 80%;
            // }

            &--quarter {
                // .fieldset .field--quarter
                width: 25%;
            }

            &--three-quarters {
                // .fieldset .field--three-quarters
                width: 75%;
            }

            // &--third {
            //     // .fieldset .field--third
            //     width: 33.333333333%;
            // }

            // &--five-twelfths {
            //     width: 41.666666666%; // 5 / 12 * 100%
            // }

            // &--seven-twelfths {
            //     width: 58.333333333%; // 7 / 12 * 100%
            // }

            &--half {
                // .fieldset .field--half
                width: 50%;
            }

            // &--two-thirds {
            //     // .fieldset .field--two-thirds
            //     width: 66.666666666%;;
            // }

            // &--full-width {
            //     // .fieldset .field--full-width
            //     width: 100%;
            // }
        }
    }
}