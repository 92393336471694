// ******************************************************************************
// Mixins & extendible classes.
// ******************************************************************************

// ******************************************************************************
// Mixins

@mixin clearfix {
    //  clearfix hack, a way to contain floats without resorting to using presentational markup
    // http://nicolasgallagher.com/micro-clearfix-hack/
    *zoom: 1;

    &::before,
    &::after {
        display: table;
        content: '';
        line-height: 0;
    }

    &::after {
        clear: both;
    }
}

@mixin fade {
    // An animated fading transition
    transition: all 0.1s ease-in-out;
}

//==============================================================================
// 'New' product badge.
//
// Used on Home, Range & Product page templates.
//==============================================================================

@mixin label-new {
    // Applies a circular "NEW" badge positioned in the top-left corner of it's
    // container.

    //overflow: hidden;
    position: relative;

    &:before {
        // The pseudo-element styled to show circle badge and slanted text.
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        
        width: 70px;
        height: 45px;
        line-height: 45px;
        border-radius: 0;
        
        background-color: $mla-purple;
        color: $white;
        content: 'New';

        font-size: 18px;
        font-weight: 400;

        text-align: center;

        z-index: $z-label-new;
        pointer-events: none;
    }
}

@mixin aspect-ratio($percent) {
    /* Force a min-height as a percentage of element width,
       using a pseudo-element.
       (eg. @include aspect-ratio(75%); to force a minimum height:width ratio of 3:4)
   */

    &:before {
        content: '';
        display: block;
        float: left;
        width: 0;
        margin-top: $percent
    }
}

@mixin fill-container {
    // Fill the offset parent container
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

//==============================================================================
// CSS columns.
//==============================================================================

@mixin column-count($value) {
    // Usage: '@include column-count'
    -webkit-column-count: $value;
    -moz-column-count: $value;
    column-count: $value;
}

@mixin column-gap($value) {
    // Usage: '@include column-gap(10px)'
    -webkit-column-gap: $value;
    -moz-column-gap: $value;
    column-gap: $value;
}

@mixin column-rule($value) {
    // Usage: '@include column-rule(2px solid grey)'
    -webkit-column-rule: $value;
    -moz-column-rule: $value;
    column-rule: $value;
}

@mixin break-inside($value) {
    // Usage: '@include break-inside(avoid)'
    -webkit-column-break-inside: $value;
    page-break-inside: $value;
    break-inside: $value;
}

@mixin break-after($value) {
    // Usage: '@include break-inside(avoid)'
    -webkit-column-break-after: $value;
    page-break-after: $value;
    break-after: $value;
}


// ******************************************************************************
// Extendible classes

%mobile-divider {
    // An extend only selector for dividing panels with a grey border on mobile view
    border-bottom: 18px solid $dark-grey;
    @media #{$desktop} {
        border-bottom: 0;
        box-sizing: border-box;
    }
}

// Truncated text

%text-truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


//==============================================================================
// Line clamp.
//==============================================================================

@mixin line-clamp($value){
    // Usage: '@include line-clamp(3)'
    display: -webkit-box;
    -webkit-line-clamp: $value;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

//==============================================================================
// User-select
//
// Vendor-prefixes for 
// https://www.w3.org/TR/css-ui-4/#propdef-user-select
//==============================================================================
@mixin user-select($value){
    // Usage: '@include user-select(auto|text|none|contain|all)'
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

//==============================================================================
// Appearance property.
//
// Vendor-prefixes for 
// https://drafts.csswg.org/css-ui-4/#appearance-switching
//==============================================================================
@mixin appearance($value){
    // Usage: '@include appearance(auto|none)'
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

//==============================================================================
// Content width.
//
// Uses "ch" character width, with percentage based fallback for legacy browsers
//==============================================================================
@mixin content-width {
    // Usage: '@include content-width'
    max-width: 65%;
    max-width: 90ch;
}

//==============================================================================
// Scrollbar.
//
// Customises the native browser scrollbar.
//
// Usage: @include scrollbar(10px, 5px);
//==============================================================================
@mixin scrollbar($size: 10px, $border-radius: 0) {
    
    &::-webkit-scrollbar {
        // ::-webkit-scrollbar
        // The background of the scrollbar, covered by the other elements.
        height: $size;
        width: $size;

        &-button {
            // ::-webkit-scrollbar-button
            // Directional buttons on the scrollbar.
            height: 0;
            width: 0;
        }

        &-corner {
            // ::-webkit-scrollbar-corner
            // Bottom corner of the scrollable element, where two
            // scrollbars meet.
            background: transparent;
        }

        &-thumb {
            // ::-webkit-scrollbar-thumb
            // Draggable scrolling handle that resizes depending on the size
            // of the scrollable area.
            background-color: rgba(0, 0, 0, 0.2);
            box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), 
                        inset 0 -1px 0 rgba(0, 0, 0, 0.07);
            min-height: 30px;
            border-radius: $border-radius;
            margin: 2px;

            &:hover {
                // ::-webkit-scrollbar-thumb:hover
                background-color: rgba(black, 0.4);
                box-shadow: inset 1px 1px 1px rgba(black, 0.25);
            }

            &:active {
                // ::-webkit-scrollbar-thumb:active
                background-color: rgba(black, 0.5);
                box-shadow: inset 1px 1px 3px rgba(black, 0.35);
            }
        }
        
        &-track {
            // ::-webkit-scrollbar-track
            // The track (progress bar) of the scrollbar.
            background-color: rgba(black, 0.025);
            box-shadow: inset 1px 0 0 rgba(black, 0.05);
            
            &:hover {
                // ::-webkit-scrollbar-track:hover
                background-color: rgba(black, 0.05);
                box-shadow: inset 1px 0 0 rgba(black, 0.1);
            }

            &:active {
                // ::-webkit-scrollbar-track:active
                background-color: rgba(black, 0.05);
                box-shadow: inset 1px 0 0 rgba(black, 0.14), 
                            inset -1px 0 0 rgba(black, 0.07);
            }
        }
    }
}
